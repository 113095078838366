import NewsStockSlider from "./NewsStockSlider";
import { ref, set, update } from "firebase/database";
import moment from "moment";
import { database } from "../firebase";
import { actionTypes } from "../reducer";
import { useStateValue } from "../StateProvider";
import { useState } from "react";
import AnimatedGradientView from "./AnimatedGradientView";

function NewsCard({ news, bookmarknews, readnews, storedsymbols, chat }) {
  const [{ send, user }, dispatch] = useStateValue();
  let changedNewsTitle = news.newsTitle.replace(/[^A-Z0-9]+/gi, "");
  const [isBookmarked, setIsBookmarked] = useState(false);
  //setIsBookmarked(bookmarknews.includes(changedNewsTitle));
  let isRead = readnews.includes(changedNewsTitle);
  let sendSelectedNews = send.enabled && send.data.newsTitle === news.newsTitle;
  const [enableAI, setenableAI] = useState(false);
  const [summary, setsummary] = useState("");
  const [loading, setloading] = useState(false);
  const isChat = chat === true;
  const [link] = useState("web.opiniontrade.ai/share/" + news.newsLink);

  const copyToClipboard = () => {
    const textToCopy = "https://" + link;
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    textArea.style.position = "fixed";
    textArea.style.opacity = 0;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      console.log("Link copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy link:", err);
    }

    document.body.removeChild(textArea);
  };

  const copyTitle = () => {
    const textToCopy = news.newsTitle;
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    textArea.style.position = "fixed";
    textArea.style.opacity = 0;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      console.log("Link copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy link:", err);
    }

    document.body.removeChild(textArea);
  };

  const fetchLinkData = () => {
    try {
      const errorMessage = "AI Summary Not Available";

      // Change expensiveAICallPerUserPerNews to true if you really want to run a demo mode where server is not
      //populating the AI Summary. Setting this to true will fetch AI summary per user per instance of mobile APP
      const expensiveAICallPerUserPerNews = false; //NEVER set this to True unless authorized - Check Again

      if (news.aisummary === null || news.aisummary === errorMessage) {
        if (expensiveAICallPerUserPerNews === false) {
          setsummary(
            news.aisummary === null
              ? "AI is not available, please try again later"
              : news.aisummary
          );
          setloading(false);
        }

        if (expensiveAICallPerUserPerNews === true) {
          const url = news.newsLink;
          fetch(`https://opiniontrade.ai/opinion/getNews?news=${url}`)
            .then((resp) => resp.json())
            .then((respJson) => {
              console.log("webpageText", respJson);
              if (
                respJson.aisummary === undefined ||
                respJson.aisummary === ""
              ) {
                setsummary("AI content is available only for latest news");
              } else {
                setsummary(respJson.aisummary);
              }
              setloading(false);
            })
            .catch((error) => {
              console.log("Error fetching webpage:", error);
            });
        }
      } else {
        setsummary(
          news.aisummary === null
            ? "AI content is available only for latest news"
            : news.aisummary
        );
        setloading(false);
      }
    } catch (error) {
      console.log("Error fetching webpage:", error);
    }
  };

  const onClickEnableAI = async () => {
    setloading(true);
    setenableAI(!enableAI);
    fetchLinkData();
  };

  if (news === undefined) return null;

  return (
    <AnimatedGradientView
      enabled={enableAI}
      text={summary}
      loading={loading}
      sendSelectedNews={sendSelectedNews}
      isChat={isChat}
    >
      <div
        className={`py-4 border-b sm:px-3 transition hover:bg-[#d0f5ed] ${
          isRead ? "bg-[#f0f0f0]" : "bg-white"
        } relative ${sendSelectedNews ? "z-[100]" : ""} ${
          enableAI ? "bg-[#d0f5ed] rounded-t-[10px]" : "rounded-[10px]"
        } ${
          !enableAI && !sendSelectedNews
            ? isChat
              ? "rounded-[10px]"
              : "rounded-[0px] "
            : ""
        }`}
        key={news.changedNewsTitle}
      >
        <div className="flex flex-col justify-between ml-3 sm:ml-0 mb-2">
          <div
            className={`flex justify-between items-center ${
              isChat ? "pr-[25px]" : ""
            }`}
          >
            <h3
              className="flex-1 text-md font-semibold pr-2 text-black"
              onClick={() => {
                copyTitle()
                window.open(
                  `${news.newsLink}`,
                  "_blank",
                  "toolbar=0,location=0,menubar=0,left=500px,top=50px,height=600,width=800"
                );
                if (!isRead) {
                  update(
                    ref(
                      database,
                      `/users/${user.phoneNumber}/readnews/${changedNewsTitle}/`
                    ),
                    news
                  );
                }
              }}
            >
              {news.newsTitle}
            </h3>
            <div
              className={`aiButtonDiv flex h-4 ${
                enableAI
                  ? "bg-gradient-to-r from-cyan-500 to-blue-500"
                  : "bg-gray-200"
              }  justify-center items-center mr-2 py-[13px] px-[10px] rounded-full hover:bg-gradient-to-r from-cyan-500 to-blue-500 transition-all duration-200 group hover:text-white ${
                isChat ? "absolute top-[10px] right-[0px] cursor-pointer" : ""
              }`}
              onClick={() => onClickEnableAI()}
            >
              <p
                className={`transition-colors font-semibold text-[10px] ${
                  enableAI
                    ? "text-[12px] text-[#fff] font-bold italic"
                    : "text-[#000]"
                }`}
              >
                AI+
              </p>
            </div>
            <div>
              {/* A button that is visually hidden */}
              <button
                onClick={copyToClipboard()}
                style={{ display: "none" }} // This hides the button
                id="copyButton"
              >
                Copy
              </button>
            </div>
            {user && !isChat ? (
              <div
                className={`flex h-4 ${
                  sendSelectedNews ? "bg-[#1ab394] text-white" : "bg-gray-200"
                } justify-center items-center mr-2 py-[13px] px-[10px] rounded-full hover:bg-[#1ab394] hover:text-white transition-all duration-200`}
                onClick={() => {
                  if (sendSelectedNews) {
                    dispatch({
                      type: actionTypes.SEND_NEWS,
                      sendenabled: false,
                      news: [],
                      room: [],
                    });
                    dispatch({
                      type: actionTypes.SET_COMMENT,
                      comment: "",
                    });
                    dispatch({
                      type: actionTypes.SET_COMMENT,
                      comment: "",
                    });
                  } else {
                    dispatch({
                      type: actionTypes.SEND_NEWS,
                      sendenabled: true,
                      news,
                      room: [],
                    });
                  }
                }}
              >
                {sendSelectedNews && (
                  <div className="flex w-3 h-3 mr-[5px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      viewBox="0 0 320 512"
                    >
                      <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                    </svg>
                  </div>
                )}

                <p className="font-semibold text-[10px]">
                  {sendSelectedNews ? "Selected" : "Send"}
                </p>
              </div>
            ) : null}
            {!isChat && (
              <div
                className="flex w-4 h-4"
                onClick={() => {
                  setIsBookmarked(bookmarknews.includes(changedNewsTitle));
                  if (isBookmarked) {
                    //setIsProcessing(false)
                    set(
                      ref(
                        database,
                        `/users/${user.phoneNumber}/bookmarknews/${changedNewsTitle}/`
                      ),
                      null
                    );
                    setIsBookmarked(false);
                  } else {
                    update(
                      ref(
                        database,
                        `/users/${user.phoneNumber}/bookmarknews/${changedNewsTitle}/`
                      ),
                      { news, addedOn: moment().valueOf() }
                    );
                    setIsBookmarked(true);
                  }
                }}
              >
                {user ? (
                  isBookmarked ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path d="M48 0H336C362.5 0 384 21.49 384 48V487.7C384 501.1 373.1 512 359.7 512C354.7 512 349.8 510.5 345.7 507.6L192 400L38.28 507.6C34.19 510.5 29.32 512 24.33 512C10.89 512 0 501.1 0 487.7V48C0 21.49 21.49 0 48 0z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path d="M336 0h-288C21.49 0 0 21.49 0 48v431.9c0 24.7 26.79 40.08 48.12 27.64L192 423.6l143.9 83.93C357.2 519.1 384 504.6 384 479.9V48C384 21.49 362.5 0 336 0zM336 452L192 368l-144 84V54C48 50.63 50.63 48 53.1 48h276C333.4 48 336 50.63 336 54V452z" />
                    </svg>
                  )
                ) : null}
              </div>
            )}
          </div>

          <div className="text-xs italic text-gray-400 ml-0">
            Source: {news.newsSite}
          </div>
        </div>
        <div className="flex items-center">
          {news.refStockQuote?.length > 0 ? (
            <div className="flex overflow-y-hidden items-center overflow-x-scroll pl-3 sm:pl-0">
              {news.refStockQuote.map((stockArr, index) => {
                return (
                  <NewsStockSlider
                    key={index}
                    stockArr={stockArr}
                    storedsymbols={storedsymbols}
                    index={index}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </AnimatedGradientView>
  );
}

export default NewsCard;
