import { onValue, ref } from "firebase/database";
import { useEffect, useState, useRef } from "react";
import { database } from "../../firebase";
import Sidebar from "../Sidebar";
import { actionTypes } from "../../reducer";
import { useStateValue } from "../../StateProvider";
import NewsCard from "../../components/NewsCard";
import NewsStockSlider from "../../components/NewsStockSlider";
import MarketMovers from "../../components/MarketMovers";
import Header from "../../components/Header";

function MostReadTab() {
  const [loading, setloading] = useState(true);
  const [news, setnews] = useState([]);
  const [readnews, setreadnews] = useState([]);
  const [bookmarknews, setbookmarknews] = useState([]);
  const [{ user, tab, send, sharednews, selectedstocks, search }] =
    useStateValue();
  const [storedsymbols, setstoredsymbols] = useState([]);
  const [pageNumber, setPageNumber] = useState(1); // Track the page number
  const [prevPage, setPrevPage] = useState(0); // storing prev page number
  const [lastList, setLastList] = useState(false);
  const listInnerRef = useRef();
  const [showFetchMore, setShowFetchMore] = useState(false);
  const [hasMoreNews, setHasMoreNews] = useState(true);

  useEffect(() => {
    setloading(true);
    setnews([]);
    getNews();
  }, [tab, selectedstocks]);

  useEffect(() => {
    setloading(true);
    setnews([]);
    if (user) {
      const userInfo = ref(database, `/users/${user.phoneNumber}/`);
      onValue(
        userInfo,
        (snapshot) => {
          if (!snapshot.exists) {
            setreadnews([]);
            setbookmarknews([]);
          } else {
            let snapVal = snapshot.val();
            if (
              snapVal.bookmarknews !== undefined &&
              snapVal.bookmarknews !== null
            ) {
              setreadnews(Object.keys(snapVal.readnews));
              setbookmarknews(Object.keys(snapVal.bookmarknews));
            }
          }
        },
        (err) => {
          setreadnews([]);
          setbookmarknews([]);
        }
      );
      const watchlistInfo = ref(
        database,
        `/users/${user.phoneNumber}/watchlist`
      );
      onValue(
        watchlistInfo,
        (snapshot) => {
          if (!snapshot.exists) {
            setstoredsymbols([]);
          } else {
            let snapVal = snapshot.val();
            if (snapVal !== undefined && snapVal !== null) {
              setstoredsymbols(Object.keys(snapVal));
            }
          }
        },
        (err) => {
          console.log("error getting watchlist", err);
          setstoredsymbols([]);
        }
      );
    } else {
      // dispatch({
      //   type: actionTypes.SET_TAB,
      //   tab: 1,
      // });
      setreadnews([]);
      setbookmarknews([]);
    }
    getNews();
  }, [user]);

  useEffect(() => {
    if (!search.enabled) {
      getNews();
    }
  }, [search.enabled]);

  const getNews = () => {
    // Extract symbols from selectedstocks
    let stocks = selectedstocks.map((stock) => stock.symbol).join("&ticker=");
    let url = `${
      process.env.REACT_APP_SERVER_ADDRESS
    }/opinion/news?sort=readCount&pageNumber=0&pageSize=50${
      stocks ? "&ticker=" + stocks : ""
    }`;

    setloading(true);
    fetch(url)
      .then((resp) => resp.json())
      .then((respJson) => {
        if (respJson.length > 0) {
          setnews(respJson);
          //setHasMore(true);
        } else {
          setnews([]);
        }
        setloading(false);
      })
      .catch((error) => {
        setnews([]);
        setloading(false);
        //setHasMore(false);
      });
  };

  const getMoreNews = () => {
    // Extract symbols from selectedstocks
    let stocks = selectedstocks.map((stock) => stock.symbol).join("&ticker=");
    let url = `${
      process.env.REACT_APP_SERVER_ADDRESS
    }/opinion/news?sort=readCount&pageNumber=${pageNumber}&pageSize=50${
      stocks ? "&ticker=" + stocks : ""
    }`;

    //setloading(true);
    fetch(url)
      .then((resp) => resp.json())
      .then((respJson) => {
        if (!respJson.length) {
          setLastList(true);
          return;
        }
        if (news.length === 0) {
          setHasMoreNews(false);
        } else {
          setPrevPage(pageNumber);
          setnews((prevNews) => [...prevNews, ...respJson]);
          setloading(false);
          //setShowFetchMore(false);
        }
      })
      .catch((error) => {
        setnews([]);
        setloading(false);
        setLastList(false);
      });
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setShowFetchMore(true);
        setPageNumber(pageNumber + 1);
        getMoreNews();
      }
    }
  };

  if (loading) {
    return (
      <div
        className={`flex flex-col flex-1 overflow-y-scroll scrollbar-hide bg-white border-r-0 sm:border-r-[5px] cursor-pointer ${
          user ? "" : "rounded-r-3xl"
        }`}
      >
        <MarketMovers storedsymbols={storedsymbols} />
        <Sidebar mobile={true} />
        {/*renderDisplayNews()*/}
        <Header
          title="Most Read News"
          icon={
            <svg
              height="25px"
              width="25px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="#0d5446"
            >
              <path d="M0 219.2v212.5c0 14.25 11.62 26.25 26.5 27C75.32 461.2 180.2 471.3 240 511.9V245.2C181.4 205.5 79.99 194.8 29.84 192C13.59 191.1 0 203.6 0 219.2zM482.2 192c-50.09 2.848-151.3 13.47-209.1 53.09C272.1 245.2 272 245.3 272 245.5v266.5c60.04-40.39 164.7-50.76 213.5-53.28C500.4 457.9 512 445.9 512 431.7V219.2C512 203.6 498.4 191.1 482.2 192zM352 96c0-53-43-96-96-96S160 43 160 96s43 96 96 96S352 149 352 96z" />
            </svg>
          }
        />
        <div className="flex justify-center items-center h-screen">
          {/* Spinner */}
          <svg
            role="status"
            className="inline w-10 h-10 mr-2 text-gray-200 animate-spin fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9765 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9765 100 50.5908ZM9.08136 50.5908C9.08136 73.1895 27.4013 91.5095 50 91.5095C72.5987 91.5095 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67217 50 9.67217C27.4013 9.67217 9.08136 27.9921 9.08136 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5532C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7232 75.2124 7.41289C69.5422 4.10259 63.2754 1.94025 56.7222 1.05125C51.7664 0.367118 46.7345 0.446408 41.8192 1.27873C39.297 1.69443 37.8266 4.19778 38.4637 6.62326C39.1008 9.04874 41.5888 10.4711 44.0875 10.1071C47.8291 9.52487 51.6295 9.52678 55.3674 10.1123C60.8745 10.9557 66.1352 13.0003 70.7281 16.0997C75.321 19.1991 79.149 23.2737 81.8832 28.0785C84.1122 31.9221 85.7086 36.1578 86.603 40.571C87.1583 43.0158 89.5421 44.6366 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <p className="text-gray-600 mt-4">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`flex-1 scrollbar bg-white border-r-0 sm:border-r-[5px] cursor-pointer ${
        user ? "" : "sm:rounded-r-3xl"
      } ${send.enabled ? "overflow-y-hidden" : "overflow-y-scroll"}`}
      onScroll={onScroll}
      ref={listInnerRef}
    >
      <MarketMovers storedsymbols={storedsymbols} />
      <Sidebar mobile={true} />
      <Header
        title="Most Read News"
        icon={
          <svg
            height="25px"
            width="25px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="#0d5446"
          >
            <path d="M0 219.2v212.5c0 14.25 11.62 26.25 26.5 27C75.32 461.2 180.2 471.3 240 511.9V245.2C181.4 205.5 79.99 194.8 29.84 192C13.59 191.1 0 203.6 0 219.2zM482.2 192c-50.09 2.848-151.3 13.47-209.1 53.09C272.1 245.2 272 245.3 272 245.5v266.5c60.04-40.39 164.7-50.76 213.5-53.28C500.4 457.9 512 445.9 512 431.7V219.2C512 203.6 498.4 191.1 482.2 192zM352 96c0-53-43-96-96-96S160 43 160 96s43 96 96 96S352 149 352 96z" />
          </svg>
        }
      />
      <ul className="mt-0 relative">
        {sharednews.newsTitle && (
          <NewsCard
            key={"sharednews"}
            news={sharednews}
            bookmarknews={bookmarknews}
            readnews={readnews}
            storedsymbols={storedsymbols}
          />
        )}
        {news &&
          news.map((newsItem, index) => (
            <div key={index}>
              <NewsCard
                news={newsItem}
                bookmarknews={bookmarknews}
                readnews={readnews}
                storedsymbols={storedsymbols}
              />

              {/* Only render "Fetching more news..." after the last news item */}
              {index === news.length - 1 && hasMoreNews && !lastList && (
                <div className="flex flex-col justify-center items-center py-4 text-gray-500">
                  <div>Fetching more news...</div>
                  <svg
                    role="status"
                    className="inline w-10 h-10 mt-4 text-gray-200 animate-spin fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9765 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9765 100 50.5908ZM9.08136 50.5908C9.08136 73.1895 27.4013 91.5095 50 91.5095C72.5987 91.5095 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67217 50 9.67217C27.4013 9.67217 9.08136 27.9921 9.08136 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5532C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7232 75.2124 7.41289C69.5422 4.10259 63.2754 1.94025 56.7222 1.05125C51.7664 0.367118 46.7345 0.446408 41.8192 1.27873C39.297 1.69443 37.8266 4.19778 38.4637 6.62326C39.1008 9.04874 41.5888 10.4711 44.0875 10.1071C47.8291 9.52487 51.6295 9.52678 55.3674 10.1123C60.8745 10.9557 66.1352 13.0003 70.7281 16.0997C75.321 19.1991 79.149 23.2737 81.8832 28.0785C84.1122 31.9221 85.7086 36.1578 86.603 40.571C87.1583 43.0158 89.5421 44.6366 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <p className="text-gray-600 mt-4">Loading...</p>
                </div>
              )}
            </div>
          ))}
      </ul>
    </div>
  );
}

export default MostReadTab;
