import React, { useEffect, useState } from "react";
import { useStateValue } from "../StateProvider";
import MarketMoversStockSlider from "./MarketMoversStockSlider";

export default function MarketMovers({ storedsymbols }) {
  const [{ user }, dispatch] = useStateValue();
  const [selectedMarketMoverType, setSelectedMarketMoverType] =
    useState("active");
  const [mostactive, setmostactive] = useState([]);
  const [topgainers, settopgainers] = useState([]);
  const [toplosers, settoplosers] = useState([]);
  const [loading, setLoading] = useState(true);

  let filters = [
    {
      id: "active",
      text: "Most Active Stocks",
    },
    {
      id: "gainer",
      text: "Top Gainers (intraday)",
    },
    {
      id: "loser",
      text: "Top Losers (intraday)",
    },
  ];

  useEffect(() => {
    Promise.all([getMostActive(), getTopGainers(), getTopLosers()])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const getMostActive = () => {
    return fetch(
      `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/mostactive`
    )
      .then((data) => data.json())
      .then((datajson) => {
        setmostactive(datajson);
      });
  };

  const getTopGainers = () => {
    return fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/gainers`)
      .then((data) => data.json())
      .then((datajson) => {
        settopgainers(datajson);
      });
  };

  const getTopLosers = () => {
    return fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/losers`)
      .then((data) => data.json())
      .then((datajson) => {
        settoplosers(datajson);
      });
  };

  const renderMarketMoverType = () => {
    if (selectedMarketMoverType === "active") {
      return (
        <div className="flex flex-col flex-1">
          <div>
            {mostactive && mostactive.length > 0 ? (
              <div className="flex mt-2 overflow-y-scroll gap-2">
                {mostactive.map((stockArr, index) => {
                  return (
                    <MarketMoversStockSlider
                      key={index}
                      stockArr={stockArr}
                      storedsymbols={storedsymbols}
                      index={index}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      );
    } else if (selectedMarketMoverType === "gainer") {
      return (
        <div className="flex flex-col flex-1">
          <div>
            {topgainers && topgainers.length > 0 ? (
              <div className="flex mt-2 overflow-y-scroll gap-2">
                {topgainers.map((stockArr, index) => {
                  return (
                    <MarketMoversStockSlider
                      key={index}
                      stockArr={stockArr}
                      storedsymbols={storedsymbols}
                      index={index}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col flex-1">
          <div>
            {toplosers && toplosers.length > 0 ? (
              <div className="flex mt-2 overflow-y-scroll gap-2">
                {toplosers.map((stockArr, index) => {
                  return (
                    <MarketMoversStockSlider
                      key={index}
                      stockArr={stockArr}
                      storedsymbols={storedsymbols}
                      index={index}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="flex sticky top-0 z-50 flex-col flex-1 p-4 pr-0 bg-[rgba(243,244,246)] border-b-[1px] border-gray-200/50">
      <div className="flex md:flex-row flex-col">
        <p className="font-black text-gray-500">MARKET MOVERS</p>
        <div className="flex md:ml-4 gap-2 mt-2 md:mt-0">
          {filters.map((fItem) => {
            let isSelected = fItem.id === selectedMarketMoverType;
            return (
              <div
                key={fItem.id}
                className={`${
                  isSelected ? "bg-[#1c74e9]/10 " : "hover:bg-[#f1f3f4]"
                }px-[8px] py-[2px] rounded-[12px] cursor-pointer`}
                onClick={() => setSelectedMarketMoverType(fItem.id)}
              >
                <p
                  className={`${
                    isSelected ? "text-[#185abc]" : "text-[#3c4043]"
                  } text-[.775rem] font-bold`}
                >
                  {fItem.text}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      {loading ? (
        <div className="flex flex-col flex-1">
          <div className="flex mt-2 overflow-x-hidden gap-2">
            {Array(6)
              .fill("")
              .map((_, index) => (
                <div
                  key={index}
                  className="w-[183px] min-w-[183px] h-[47px] border-[1px] border-[#dadce0] rounded-lg bg-white p-[6px] gap-1 animate-pulse flex items-center"
                >
                  <div className="w-[32px] h-[32px] min-w-[32px] mr-[8px] bg-gray-300 rounded-[8px]"></div>
                  <div className="flex-1 flex flex-col gap-2 justify-center">
                    <div className="w-8/12 h-2 bg-gray-300 rounded"></div>
                    <div className="w-4/12 h-2 bg-gray-300 rounded"></div>
                  </div>
                  <div className="flex-1 flex flex-col gap-2 justify-center items-end">
                    <div className="w-full h-2 bg-gray-300 rounded"></div>
                    <div className="w-5/12 h-2 bg-gray-300 rounded"></div>
                  </div>
                  <div className="w-6 h-6 ml-[6px] bg-gray-300 rounded-full"></div>
                </div>
              ))}
          </div>
        </div>
      ) : (
        renderMarketMoverType()
      )}
    </div>
  );
}
